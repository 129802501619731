/*@import '~@angular/material/prebuilt-themes/indigo-pink.css';*/
@import 'assets/iconfont/iconfont.css';
@import 'assets/styles/variables.css';
@import 'assets/styles/tiptap.css';
html {
  overflow: hidden;
}
body {
  font-family: sans-serif !important;
}

@supports (-moz-appearance:none) {
  body {
    height: 100vh !important;
    width: 100vw !important;
  }
}

.voc {
  width: 8.43rem!important;
}

.user {
  width: 7.5rem!important;
}

.time {
  width: 12.2rem!important;
}

.status {
  width: 6.56rem!important;
}

nb-layout-header {
  z-index: 100 !important;
}

.hidden {
  display: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

* {
  text-shadow: inherit !important;
}

.main-container {
  max-width: 150px !important;
}

/*一些比较暴力的部分*/
.ant-table {
  color: black !important;
}

.ant-tabs .ant-tabs-small-bar .ant-tabs-tab {
  padding: 19px 16px !important;
}

/*.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {*/
/*  opacity: 0 !important;*/
/*}*/

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.65)!important;
}

.ant-pagination, .ant-pagination ol, .ant-pagination ul {
  /*padding-left: 60% !important;*/
  height: 56px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.ant-pagination {
  text-align: right!important;
}

.page-container {
  top: 1em;
  font-size: 14px;
  min-width: 1500px;
  padding: 8px 16px!important;
  background: white;
}

.dashed-border-container {
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
}

.section-tip-container {
  margin: 8px;
}

.option-card .ant-card-body {
  padding: 0;
}

.option-card .ant-card-body .ant-card-grid {
  padding: 0;
  width: 100%;
}

.option-card .ant-card-body .ant-card-grid:first-child {
  color: green;
}

.weight-input .ant-input-number-input {
  height: 21px;
}

.book-tree-container .draggable {
  cursor: move !important;
}

/*文本编辑器样式*/
ngx-edit-copywriting-component .ck.ck-editor__main > .ck-editor__editable {
  height: 500px !important;
  overflow-y: auto;
}

ngx-edit-copywriting-component .ck.ck-reset.ck-editor.ck-rounded-corners {
  width: 375px;
}

ngx-edit-copywriting-component .ck.ck-button {
  padding: 1px !important;
  margin: 0 !important;
}

ngx-edit-copywriting-component .ck.ck-button.ck-button_with-text.ck-dropdown__button {
  padding: 0 0 0 4px;
  width: 39px !important;
}

ngx-edit-copywriting-component .versionArea .anticon.ant-collapse-arrow.ng-star-inserted {
  position: absolute;
  right: 16px;
  left: auto;
}

ngx-edit-copywriting-component .versionArea .ant-collapse-header {
  font-weight: 600;
}

ngx-edit-copywriting-component .versionArea .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
}

ngx-edit-copywriting-component .versionArea .ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none;
}

ngx-edit-copywriting-component .ck-content p {
  font-size: 13px;
  color: #808080;
}

ngx-problem-management .ck.ck-editor__main > .ck-editor__editable {
  min-height: 300px !important;
}

.ant-card-head.ng-star-inserted {
  border: none;
}

.ck.ck-icon {
  height: 18px !important;
}

.ck-placeholder::before {
  color: #d0cfd0 !important;
}

ngx-customer-service .ant-collapse-header {
  padding: 12px !important;
}

ngx-customer-service .ant-collapse-content-box {
  padding: 12px !important;
}

ngx-emoji-list .ant-carousel .slick-list .slick-slide {
  width: 355px !important;
  height: 390px !important;
  background: #f5f5f5;
  color: #fff;
  text-align: left;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

ngx-emoji-list .ant-carousel .slick-list .slick-slide span {
  display: inline-block;
  padding: 8px;
  font-size: 20px;
  border-radius: 4px;
}

ngx-emoji-list .ant-carousel .slick-list .slick-slide.slick-active span:hover {
  background: #fff;
}

ngx-emoji-list .ant-carousel  button {
  background: #bfbfbf !important;
}

ngx-customer-service .dialogueListDiv:last-child .roomBox.ant-layout-content {
  border: none !important;
}

ngx-customer-service .resultListDiv:nth-last-child(2) .resultBox {
  border: none !important;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar:horizontal {
  height: 6px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.35);
  border-radius: 10px;
}

figure.image img {
  max-width: 220px;
}

figure.image img {
  max-width: 100%;
}

.input-text-and-image {
  width: 100%;
  min-height: 150px;
  outline: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow: auto;
}

.input-text-and-image img {
  margin: 0 auto;
  max-width: 300px;
  max-height: 300px;
}

.input-text-and-image div {
  display: flex;
}

.msgDiv img {
  max-height: 80px;
  width: auto;
}

.msgDiv img {
  max-width: 100%;
}

.input img {
  max-height: 100px;
  max-width: 100px;
}

.msgVideo {
  max-height: 160px;
  max-width: 160px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.videoContainer {
  position: relative;
}

.videoContainer::before {
  position: absolute;
  top: 37%;
  left: 37%;
  width: 100%;
  height: 100%;
  content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAeFBMVEUjGBUAAAD8/Pyuqqn+/v7i4OD9/f3w8O/T0dA2LCr5+fn19fTv7+7p6OjIxcRkXFn08/Pv7u7r6urLycjm5eSkn57i4OBORUPg3t7i4ODT0dDb2dj8/PxvaGbb2dj19PTg3t7PzMy8uLeRjIp4cW/W1NTV09L///9Q5A+bAAAAJ3RSTlMzAPFm+6b1sY4348rGtoBCwJ+Rg3RgSj0qHgoG9UYS2KKJcVVJkpHKJcpVAAABH0lEQVQ4y42VV3LDMAxEYZhNjepddnpw/xsmjhOLtGgF71dvhqMlsISDy9xVRayIVFxU3ex9csWhRMJUmro2MkXCcgiKoxYo2wh+iVqJQo9bsVFoevDoDarmTpw0ZTlsyDPSkyueE2EhiBXJeRWnZDnCA45LMt1ELXzPN4X+ExuysIOl5iqOKoNdMjX+iBrzfTFHfREHYeAfjBi+xRLdnG3yfNqIPZYHmFHCyomIlo/o3pQ4Q0etmwVdiF/Bp6UOKow88Ur65okRVlCkEBBJSC+KtIBY+uINfHGOkjEo44sOT+9rQAqofijSemM1sUX20eyfYcfDDpx9haGh+AwNBXfM2IPLXQX2cnHXlVsA7EphlxS/9vhFyq9mftmzn48vOesnttWY6YcAAAAASUVORK5CYII=');
}

.robot {
  color : #36b59d;
}

.replenish {
  display: inline;
}

.replenish .log {
  display: none;
}

.replenish .arrow-down {
  display : inline-block;
  position: relative;
  width: 20px;
  height: 3px;
  margin-right: 20px;
  /*font-size: 0;*/
  /*line-height: 0;*/
  /*border-width: 6px;*/
  /*border-color: black;*/
  /*border-bottom-width: 0;*/
  /*border-style: dashed;*/
  /*border-top-style: solid;*/
  /*border-left-color: transparent;*/
  /*border-right-color: transparent;*/
}

.replenish .arrow-down::after {
  display: inline-block;
  content: ' ';
  height: 8px;
  width: 8px;
  border-width: 0 2px 2px 0;
  border-color: #999999;
  border-style: solid;
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  transform-origin: center;
  transition: transform 0.3s;
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -10px;
}

.replenish .arrow-up::after {
  display: inline-block;
  content: ' ';
  height: 8px;
  width: 8px;
  border-width: 0 2px 2px 0;
  border-color: #999999;
  border-style: solid;
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -10px;
  transform-origin: center;
  transform: rotate(-135deg);
  transition: transform 0.3s;
}

.replenish .arrow-up {
  display : inline-block;
  position: relative;
  width: 20px;
  height: 3px;
  margin-right: 20px;
  /*font-size: 0;*/
  /*line-height: 0;*/
  /*border-width: 6px;*/
  /*border-color: black;*/
  /*border-top-width: 0;*/
  /*border-style: dashed;*/
  /*border-bottom-style: solid;*/
  /*border-left-color: transparent;*/
  /*border-right-color: transparent;*/
}

.nz-menu .ant-cascader-menu {
  height: 355px;
}

.cdk-overlay-pane.nz-dropdown-panel .ant-dropdown {
  top: 0 !important;
}

.nb {
  word-break: keep-all;
}

.solutionArea img {
  max-width: 300px;
  max-height: 300px;
}

.ck.ck-editor__editable .image-inline img {
  height: auto;
  max-width: 25% !important;
}

.problem-tabs .ant-tabs-content-holder .ant-tabs-content {
  height: 100%;
}
