
@font-face {
  font-family: 'iconfont';
  src: url('iconfont.ttf?t=1533695690339') format('truetype');

}

.iconfont {
  font-family: 'iconfont' !important;
  font-size:16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-list::before { content: '\e621'; }

.icon-book::before { content: '\e623'; }

.icon-arrow::before { content: '\e625'; }

.icon-calendar::before { content: '\e626'; }

.icon-hook::before { content: '\e627'; }

.icon-arrow_::before { content: '\e629'; }

.icon-lastpage::before { content: '\e62a'; }

.icon-nextpage::before { content: '\e62c'; }

.icon-previouspage::before { content: '\e62d'; }

.icon-home::before { content: '\e62e'; }

.icon-arrow_right::before { content: '\e62f'; }

.icon-arrow_left::before { content: '\e630'; }

.icon-menu_open::before { content: '\e633'; }

.icon-menu_close::before { content: '\e634'; }

/* .icon-bianji:before { content: "\e512"; } */


